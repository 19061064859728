<template>
  <div>
    <x-select
      class="mb-3"
      v-model="inversionista_id"
      dispatchPath="inversionistaModule/getResource"
      getterListPath="inversionistaModule/getList"
      :formatListWith="['id', 'identificacion']"
      no-clear-button
      no-validate
      default-text="Seleccionar inversionista"
      prependText="Inversionista"
    />
    <x-select
      class="mb-3"
      v-model="periodo_id"
      dispatchPath="periodoModule/getResource"
      getterListPath="periodoModule/getResourceList"
      :formatListWith="['id', 'descriptor']"
      no-clear-button
      no-validate
      defaultText="-- Selecciona un periodo --"
      prependText="Periodo"
    />

    <div v-if="isThereData">
      <b-pagination size="sm" align="fill"
        v-model="currentPage"
        :total-rows="getTotalItems"
        :per-page="perPage"
        :disabled="!getAvailableList"
        aria-controls="inversionistas-table"
      ></b-pagination>
    </div>

    <b-table
      id="retencionesTable"
      v-if="isThereData"
      :fields="fields"
      :items="getResourceList"
      striped
      bordered
      responsive
      hover
      stacked="lg"
      show-empty
      samll
      :per-page="perPage"
      :current-page="currentPage">
    </b-table>

    <div class="row" v-if="isThereData">
      <div class="col-xl-3 col-md-6 mb-4">
          <!-- Dashboard info widget 1-->
          <div class="card border-top-0 border-bottom-0 border-right-0 border-left-lg border-primary h-100">
              <div class="card-body">
                  <div class="d-flex align-items-center">
                      <div class="flex-grow-1">
                          <div class="small font-weight-bold text-primary mb-1">Importe total</div>
                          <div class="h5">${{ total.importe }}</div>
                      </div>
                      <div class="ml-2"><svg class="svg-inline--fa fa-dollar-sign fa-w-9 fa-2x text-gray-200" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="dollar-sign" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 288 512" data-fa-i2svg=""><path fill="currentColor" d="M209.2 233.4l-108-31.6C88.7 198.2 80 186.5 80 173.5c0-16.3 13.2-29.5 29.5-29.5h66.3c12.2 0 24.2 3.7 34.2 10.5 6.1 4.1 14.3 3.1 19.5-2l34.8-34c7.1-6.9 6.1-18.4-1.8-24.5C238 74.8 207.4 64.1 176 64V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48h-2.5C45.8 64-5.4 118.7.5 183.6c4.2 46.1 39.4 83.6 83.8 96.6l102.5 30c12.5 3.7 21.2 15.3 21.2 28.3 0 16.3-13.2 29.5-29.5 29.5h-66.3C100 368 88 364.3 78 357.5c-6.1-4.1-14.3-3.1-19.5 2l-34.8 34c-7.1 6.9-6.1 18.4 1.8 24.5 24.5 19.2 55.1 29.9 86.5 30v48c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16v-48.2c46.6-.9 90.3-28.6 105.7-72.7 21.5-61.6-14.6-124.8-72.5-141.7z"></path></svg><!-- <i class="fas fa-dollar-sign fa-2x text-gray-200"></i> Font Awesome fontawesome.com --></div>
                  </div>
              </div>
          </div>
      </div>
      <div class="col-xl-3 col-md-6 mb-4">
          <!-- Dashboard info widget 2-->
          <div class="card border-top-0 border-bottom-0 border-right-0 border-left-lg border-secondary h-100">
              <div class="card-body">
                  <div class="d-flex align-items-center">
                      <div class="flex-grow-1">
                          <div class="small font-weight-bold text-secondary mb-1">Total traslados</div>
                          <div class="h5">${{ total.total_traslados }}</div>
                      </div>
                      <div class="ml-2"><svg class="svg-inline--fa fa-dollar-sign fa-w-9 fa-2x text-gray-200" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="dollar-sign" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 288 512" data-fa-i2svg=""><path fill="currentColor" d="M209.2 233.4l-108-31.6C88.7 198.2 80 186.5 80 173.5c0-16.3 13.2-29.5 29.5-29.5h66.3c12.2 0 24.2 3.7 34.2 10.5 6.1 4.1 14.3 3.1 19.5-2l34.8-34c7.1-6.9 6.1-18.4-1.8-24.5C238 74.8 207.4 64.1 176 64V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48h-2.5C45.8 64-5.4 118.7.5 183.6c4.2 46.1 39.4 83.6 83.8 96.6l102.5 30c12.5 3.7 21.2 15.3 21.2 28.3 0 16.3-13.2 29.5-29.5 29.5h-66.3C100 368 88 364.3 78 357.5c-6.1-4.1-14.3-3.1-19.5 2l-34.8 34c-7.1 6.9-6.1 18.4 1.8 24.5 24.5 19.2 55.1 29.9 86.5 30v48c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16v-48.2c46.6-.9 90.3-28.6 105.7-72.7 21.5-61.6-14.6-124.8-72.5-141.7z"></path></svg><!-- <i class="fas fa-dollar-sign fa-2x text-gray-200"></i> Font Awesome fontawesome.com --></div>
                  </div>
              </div>
          </div>
      </div>
      <div class="col-xl-3 col-md-6 mb-4">
          <!-- Dashboard info widget 3-->
          <div class="card border-top-0 border-bottom-0 border-right-0 border-left-lg border-success h-100">
              <div class="card-body">
                  <div class="d-flex align-items-center">
                      <div class="flex-grow-1">
                          <div class="small font-weight-bold text-success mb-1">Total retenidos</div>
                          <div class="h5">${{ total.total_retenciones }}</div>
                      </div>
                      <div class="ml-2"><svg class="svg-inline--fa fa-dollar-sign fa-w-9 fa-2x text-gray-200" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="dollar-sign" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 288 512" data-fa-i2svg=""><path fill="currentColor" d="M209.2 233.4l-108-31.6C88.7 198.2 80 186.5 80 173.5c0-16.3 13.2-29.5 29.5-29.5h66.3c12.2 0 24.2 3.7 34.2 10.5 6.1 4.1 14.3 3.1 19.5-2l34.8-34c7.1-6.9 6.1-18.4-1.8-24.5C238 74.8 207.4 64.1 176 64V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48h-2.5C45.8 64-5.4 118.7.5 183.6c4.2 46.1 39.4 83.6 83.8 96.6l102.5 30c12.5 3.7 21.2 15.3 21.2 28.3 0 16.3-13.2 29.5-29.5 29.5h-66.3C100 368 88 364.3 78 357.5c-6.1-4.1-14.3-3.1-19.5 2l-34.8 34c-7.1 6.9-6.1 18.4 1.8 24.5 24.5 19.2 55.1 29.9 86.5 30v48c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16v-48.2c46.6-.9 90.3-28.6 105.7-72.7 21.5-61.6-14.6-124.8-72.5-141.7z"></path></svg><!-- <i class="fas fa-dollar-sign fa-2x text-gray-200"></i> Font Awesome fontawesome.com --></div>
                  </div>
              </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

const API_MODULE = 'listadoModule'

const total = () => ({
  importe: 0,
  total_traslados: 0,
  total_retenciones: 0
})

export default {
  name: 'Retenciones',

  data () {
    return {
      fields: [
        { key: 'identificacion', label: 'Identificación', sortable: true },
        { key: 'UUID', label: 'UUID' },
        { key: 'numero', label: 'Número departamento' },
        { key: 'num_catastral', label: 'Número catastral' },
        { key: 'cuenta_predial', label: 'Cuenta predial' },
        { key: 'importe', label: 'Importe' },
        { key: 'total_traslados', label: 'Total traslados' },
        { key: 'total_retenciones', label: 'Total retenciones' }
      ],

      perPage: 10,

      currentPage: 1,

      inversionista_id: null,

      periodo_id: null,

      total: total()
    }
  },

  computed: {
    ...mapGetters(API_MODULE, [
      'getResourceList',
      'getAvailableList',
      'isThereData',
      'getResource',
      'getTotalItems'
    ])
  },

  created () {
    // reset the module state
    this.$store.dispatch(`${API_MODULE}/resetState`)
  },

  watch: {
    inversionista_id: {
      async handler (value) {
        if (value) {
          this.periodo_id = null
          await this.getRetenciones()
        }
      }
    },

    periodo_id: {
      async handler (value) {
        if (value) {
          this.inversionista_id = null
          await this.getRetenciones()
        }
      }
    }
  },

  methods: {
    async getRetenciones () {
      this.total = total()
      const { error, message } = await this.$store.dispatch(`${API_MODULE}/getRetenciones`, {
        inversionista_id: this.inversionista_id,
        periodo_id: this.periodo_id
      })

      const items = this.getResource.data

      items.forEach(item => {
        this.total.importe += parseFloat(item.importe)
        this.total.total_traslados += parseFloat(item.total_traslados)
        this.total.total_retenciones += parseFloat(item.total_retenciones)
      })

      console.log(this.total)

      if (error) this.$notify({ error, message }, 'Retenciones')
    }
  }
}
</script>
